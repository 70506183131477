/**
 * Module for authentication.
 */

import { ActionContext } from "vuex";
import { State } from ".";
import AuthService, { LoginCredentials } from "@/services/AuthService";
import UserService from "@/services/UserService";

export interface AuthState {
    loggedIn: boolean,
    user: any
}

type Context = ActionContext<AuthState, State>;

export const auth = {
    namespaced: true,
    state: { loggedIn: false, user: null },
    actions: {
        logIn(context: Context, user: LoginCredentials) {
            return AuthService.logIn(user).then(
                user => {
                    context.commit('logInSuccess', user);
                    return Promise.resolve(user);
                },
                error => {
                    context.commit('logInFailure');
                    return Promise.reject(error);
                }
            );
        },
        logInWithToken(context: Context, credentialsAndToken: { credentials: LoginCredentials, token: string }) {
            return AuthService.setPasswordAndLogin(credentialsAndToken.credentials, credentialsAndToken.token).then(
                user => {
                    context.commit('logInSuccess', user);
                    return Promise.resolve(user);
                },
                error => {
                    context.commit('logInFailure');
                    return Promise.reject(error);
                }
            );
        },
        logOut(context: Context) {
            return AuthService.logOut().finally(() => {
                context.commit('logOut');
            });
        },
        updateUserDetails(context: Context) {
            return UserService.getMe().then(
                (data: any) => {
                    context.commit('userDetailCheck', data);
                    return Promise.resolve(data);
                },
                (error: any) => {
                    context.commit('userDetailCheck');
                    return Promise.resolve(error);
                }
            );
        }
    },
    mutations: {
        logInSuccess(state: AuthState, user: any) {
            state.loggedIn = true;
            state.user = user;
        },
        logInFailure(state: AuthState) {
            state.loggedIn = false;
            state.user = null;
        },
        logOut(state: AuthState) {
            state.loggedIn = false;
            state.user = null;
        },
        userDetailCheck(state: AuthState, data: any) {
            if (data) {
                state.loggedIn = true;
                state.user = data.data;
            } else {
                state.loggedIn = false;
                state.user = null;
            }
        },
    }
};
