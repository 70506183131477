
	import { defineComponent, ref, watch } from 'vue';
	import { useStore } from 'vuex';
	import { useRouter } from 'vue-router';
    import NotificationService from '@/services/NotificationService';
	import { useToast } from 'primevue/usetoast';
	import notificationTypes from '@/notification_types.json';
	import domiciles from '@/domiciles.json';

	export default defineComponent({
        props: {
            showNotificationSettings: Number,
        },
		setup(props) {

			const router = useRouter();
			const store = useStore();
			const toast = useToast();

			const devmode = ref(store.state.ui.devmode);

			const np = ref();

			const modal = ref({
				notifications: false
			})

			const notificationsTabIndex = ref(0);

            watch(() => props.showNotificationSettings, () => {
				getNotificationSettings();
				modal.value.notifications = true;
				notificationsTabIndex.value = 1;
            });

			const loading = ref({
				notifications: false,
				notification: true
			});

			const notifications = ref({
				data: []
			});

			const notificationSettings = ref();


			const notificationData = ref({
				id: null,
				title: null,
				html: null
			} as any);

			const getNotificationTypeInfo = (notification: any, setting: boolean = false) => {
				const notificationType = notificationTypes.find((n: any) => n.name == (setting ? notification.name : notification.data.type));

				if (!notificationType) return "(No name)";

				let title = notificationType.title;

				if (setting) {
					notification.setting.mail = !!notification.setting.channels.find((c: any) => c.name == 'mail');
				} else {
					if (notificationType.count) title += ' (' + notification.data.count + ')';

					if (notificationType.domicile) title += ' (' + (domiciles.find((d: any) => d.code == notification.data.domicile))!.name + ')';
				}

				return { info: notificationType, notification, title };

			}

			const getNotifications = (page: number = 1) => {
                loading.value.notifications = true;

                NotificationService.getNotifications(page)
                .then((response: any) => {
					response.data.notifications.data = response.data.notifications.data.map((n: any) => n = getNotificationTypeInfo(n));
                    notifications.value = response.data.notifications;
                    loading.value.notifications = false;
                })
                .catch(() => {
					toast.add({severity: 'error', summary: "Getting notifications failed", life: 5000});
                    loading.value.notifications = false;
                });
			}

			const getNotificationSettings = () => {
                NotificationService.getNotificationSettings()
                .then((response: any) => {
					if (!response.data) return;
					response.data = response.data.map((n: any) => n = getNotificationTypeInfo(n, true));
					notificationSettings.value = response.data;
                })
                .catch(() => {
					toast.add({severity: 'error', summary: "Getting notification settings failed", life: 5000});
                });
			}

			const saveNotificationSettings = () => {
				const newSettings = notificationSettings.value.map((s: any) => {
					return {
						name: s.notification.name,
						enabled: s.notification.setting.enabled,
						channels: s.notification.setting.mail ? [
								s.notification.channels.find((c: any) => c.name == 'mail'),
								s.notification.channels.find((c: any) => c.name == 'database')
							]
							:
							[ s.notification.channels.find((c: any) => c.name == 'database') ]
					}
				});

                NotificationService.setNotificationSettings(newSettings)
                .then(() => {
					toast.add({severity: 'success', summary: "Settings saved", life: 3000});
                })
                .catch(() => {
					toast.add({severity: 'error', summary: "Saving notification settings failed", life: 5000});
                });

			}

			const toggleNotificationPanel = (e: any) => {
				getNotifications();
				np.value.toggle(e);
			};

			const markNotificationAsRead = (id: string|null = null) => {
				NotificationService.markAsRead(id)
                .then(() => {
					getNotifications();
					store.dispatch("auth/updateUserDetails");
                })
                .catch(() => {
					toast.add({severity: 'error', summary: "Marking notification(s) as read failed", life: 5000});
                });
			}

			const showNotification = (title: string|null = null, id: string|null = null) => {
				loading.value.notification = true;
				modal.value.notifications = true;
				notificationData.value.id = id;

				NotificationService.getNotification(id)
                .then((response: any) => {
					notificationData.value.title = title;
					notificationData.value.html = response;
					store.dispatch("auth/updateUserDetails");
					loading.value.notification = false;
					getNotifications();
                })
                .catch(() => {
					toast.add({severity: 'error', summary: "Getting notification failed", life: 5000});
					loading.value.notification = false;
                });


			}

			const hideNotification = () => {
				notificationData.value = { id: null, title: null, html: null }
			}

			const logOut = () => {
				store.dispatch("auth/logOut").then(() => {
						router.push("/login");
					}
				);
			}

			const rowClass = (d: any) => d.notification.read_at ? 'notification-read' : 'notification-unread';

			const toggleDevMode = () => {
				store.commit("ui/toggleDevMode");
			}


			return {
				devmode,
				loading,
				modal,
				notificationData,
				notifications,
				notificationSettings,
				notificationsTabIndex,
				np,
				store,

				getNotifications,
				getNotificationSettings,
				getNotificationTypeInfo,
				hideNotification,
				logOut,
				markNotificationAsRead,
				rowClass,
				saveNotificationSettings,
				showNotification,
				toggleDevMode,
				toggleNotificationPanel
			}
		}
	})
