/**
 * Defaults for axios.
 */


import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';
import store from '@/store/index';
import router from '@/router';

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    withCredentials: true
});

axiosRetry(axios, { retryDelay: (retryCount) => {
    return retryCount * 1000;
}});

const getCsrfTokenIfNeeded = async (request: AxiosRequestConfig) => {

    // Get CSRF token if needed
    if (!document.cookie.match(/^(.*;)?\s*XSRF-TOKEN\s*=\s*[^;]+(.*)?$/)) {
        await axios.get(process.env.VUE_APP_API_BASE_URL + 'csrf-cookie')
    }

    return request;
}

const logOutIfSessionExpired = async (error: AxiosError) => {
    const responseURL = error.response!.request.responseURL;
    const responsePath = responseURL.substring(responseURL.lastIndexOf('/') + 1);

    if (responsePath == 'login') {
        return Promise.reject(error.response)
    } else {
        await store.dispatch("auth/updateUserDetails").then(() => {
            if (!store.state.auth.loggedIn) {
                router.push("/?sessionexpired")
            }
        });

        return Promise.reject(error.response)
    }
    
}


instance.interceptors.request.use(
    (request) => getCsrfTokenIfNeeded(request)
);

instance.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => logOutIfSessionExpired(error)
);

export default instance;