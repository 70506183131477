import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "layout-main-container" }
const _hoisted_2 = { class: "layout-main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toast = _resolveComponent("Toast")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_AppSideMenu = _resolveComponent("AppSideMenu")!
  const _component_AppTopBar = _resolveComponent("AppTopBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AppFooter = _resolveComponent("AppFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Toast, { position: "top-right" }),
    (_ctx.$store.state.ui.loading)
      ? (_openBlock(), _createBlock(_component_ProgressBar, {
          key: 0,
          mode: "indeterminate",
          style: {"height":".25em","z-index":"1100","border-radius":"0","background":"transparent"}
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass((!_ctx.$route.meta.navigation || _ctx.$route.name == 'login') ? 'content-login' : '')
    }, [
      (_ctx.$route.meta.navigation && _ctx.$route.name && _ctx.$route.name != 'login' && _ctx.$store.state.auth.loggedIn)
        ? (_openBlock(), _createBlock(_component_AppSideMenu, {
            key: 0,
            onOpenNotificationSettings: $setup.openNotificationSettings
          }, null, 8, ["onOpenNotificationSettings"]))
        : _createCommentVNode("", true),
      (_ctx.$route.meta.navigation && _ctx.$route.name && _ctx.$route.name != 'login' && _ctx.$store.state.auth.loggedIn)
        ? (_openBlock(), _createBlock(_component_AppTopBar, {
            key: 1,
            showNotificationSettings: $setup.showNotificationSettings
          }, null, 8, ["showNotificationSettings"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(), _createBlock(_component_router_view, {
            key: _ctx.$route.name
          }))
        ])
      ]),
      (_ctx.$route.meta.navigation || _ctx.$route.name == 'login')
        ? (_openBlock(), _createBlock(_component_AppFooter, { key: 2 }))
        : _createCommentVNode("", true)
    ], 2)
  ], 64))
}