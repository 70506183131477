import { createRouter, createWebHashHistory } from "vue-router";
import store from "./store";

const checkAccess = (route: any) =>
    !route.meta.target ||
    store.state.auth.user.role.permissions.some((permission: string) =>
        route.meta.target.includes(permission)
    );

const checkActiveNavItemVisible = (route: any) => {
    return new Promise((resolve) => {
        const o = new IntersectionObserver(([entry]) => {
            resolve(entry.intersectionRatio > 0.85);
            o.disconnect();
        });

        o.observe(
            document.querySelector(
                "[data-route-name='" + route.name?.toString() + "']"
            )!
        );
    });
};

const routes = [
    // Misc. (shown in navigation)
    {
        path: "/dashboard/home",
        name: "home",
        meta: {
            parent: "",
            title: "Home",
            icon: "home",
            navigation: true,
        },
        component: () => import("./pages/HomePage.vue"),
    },
    // Misc. (not shown in navigation)
    {
        path: "/",
        alias: "/login",
        name: "login",
        meta: {
            title: "Log in",
        },
        component: () => import("./pages/LoginPage.vue"),
    },
    {
        path: "/dashboard/print-domain-report/:id/:sort?",
        name: "print-domain-report",
        meta: {
            title: "Domain report",
            target: [
                "market-data.*",
                "market-data.de.*",
                "market-data.dk.*",
                "market-data.fi.*",
                "market-data.se.*",
                "market-data.de.status",
                "market-data.dk.status",
                "market-data.fi.status",
                "market-data.se.status",
            ],
            navigation: false,
        },
        component: () =>
            import("./pages/market-data/MarketDataDomainReportPrintPage.vue"),
    },
    // System
    {
        path: "/dashboard/system-users",
        name: "system-users",
        meta: {
            parent: "System",
            title: "Users",
            icon: "user-edit",
            target: ["system.*", "system.users.*"],
            navigation: true,
        },
        component: () => import("./pages/system/SystemUsersPage.vue"),
    },
    {
        path: "/dashboard/system-job-queue",
        name: "system-job-queue",
        meta: {
            parent: "System",
            title: "Job queue",
            icon: "clone",
            target: ["system.*"],
            navigation: true,
        },
        component: () => import("./pages/system/SystemJobQueuePage.vue"),
    },
    {
        path: "/dashboard/system-task-schedule",
        name: "system-task-schedule",
        meta: {
            parent: "System",
            title: "Task schedule",
            icon: "calendar",
            target: ["system.*"],
            navigation: true,
        },
        component: () => import("./pages/system/SystemTaskSchedulePage.vue"),
    },
    // Extension
    {
        path: "/dashboard/extension-logs",
        name: "extension-logs",
        meta: {
            parent: "Extension",
            title: "Logs",
            icon: "align-left",
            target: ["system.*"],
            navigation: true,
            devmode: true,
        },
        component: () => import("./pages/extension/ExtensionLogsPage.vue"),
    },
    // Panel
    {
        path: "/dashboard/panel-panelists/:domicile?/:user_id?",
        name: "panel-panelists",
        meta: {
            parent: "Panel",
            title: "Panelists",
            icon: "id-card",
            target: ["panel.*", "panel.fi.*", "panel.se.*", "panel.dk.*"],
            navigation: true,
            domicile: true,
            devmode: true,
        },
        component: () => import("./pages/panel/PanelPanelistsPage.vue"),
    },
    {
        path: "/dashboard/panel-messages/:domicile?/:language?/:thread_id?",
        name: "panel-messages",
        meta: {
            parent: "Panel",
            title: "Messages",
            icon: "envelope",
            target: ["panel.*", "panel.fi.*", "panel.se.*", "panel.dk.*"],
            navigation: true,
            domicile: true,
            devmode: true,
        },
        component: () => import("./pages/panel/PanelMessagesPage.vue"),
    },
    {
        path: "/dashboard/panel-prizes/:domicile?/:language?/:thread_id?",
        name: "panel-prizes",
        meta: {
            parent: "Panel",
            title: "Payout requests",
            icon: "money-bill",
            target: ["panel.*", "panel.fi.*", "panel.se.*", "panel.dk.*"],
            navigation: true,
            domicile: true,
            devmode: true,
        },
        component: () => import("./pages/panel/PanelPrizesPage.vue"),
    },
    {
        path: "/dashboard/panel-hot-sites/:domicile?",
        name: "panel-hot-sites",
        meta: {
            parent: "Panel",
            title: "Hot sites",
            icon: "sun",
            target: ["panel.*", "panel.fi.*", "panel.se.*", "panel.dk.*"],
            navigation: true,
            domicile: true,
            devmode: true,
        },
        component: () => import("./pages/panel/PanelHotsitesPage.vue"),
    },
    {
        path: "/dashboard/panel-restricted-sites",
        name: "panel-restricted-sites",
        meta: {
            parent: "Panel",
            title: "Restricted sites",
            icon: "exclamation-triangle",
            target: ["panel.*", "panel.fi.*", "panel.se.*", "panel.dk.*"],
            navigation: true,
            domicile: false,
            devmode: true,
        },
        component: () => import("./pages/panel/PanelRestrictedSitesPage.vue"),
    },
    {
        path: "/dashboard/panel-daily-traffic/:domicile?",
        name: "panel-daily-traffic",
        meta: {
            parent: "Streaming data",
            title: "Daily traffic",
            icon: "globe",
            target: [
                "panel.*",
                "panel.fi.*",
                "panel.se.*",
                "panel.dk.*",
                "panel.de.*",
                "panel.fi.daily-traffic",
                "panel.se.daily-traffic",
                "panel.dk.daily-traffic",
                "panel.de.daily-traffic",
            ],
            navigation: true,
            domicile: true,
        },
        component: () => import("./pages/panel/PanelDailyTrafficPage.vue"),
    },
    {
        path: "/dashboard/panel-weekly-contributions/:domicile?",
        name: "panel-weekly-contributions",
        meta: {
            parent: "Streaming data",
            title: "Weekly contributions",
            icon: "clock",
            target: [
                "panel.*",
                "panel.fi.*",
                "panel.se.*",
                "panel.dk.*",
                "panel.de.*",
                "panel.fi.daily-traffic",
                "panel.se.daily-traffic",
                "panel.dk.daily-traffic",
                "panel.de.daily-traffic",
            ],
            navigation: true,
            domicile: true,
        },
        component: () =>
            import("./pages/panel/PanelWeeklyContributionsPage.vue"),
    },
    {
        path: "/dashboard/panel-streaming-objects/:domicile?",
        name: "panel-streaming-objects",
        meta: {
            parent: "Streaming data",
            title: "Streaming objects",
            icon: "angle-double-right",
            target: [
                "panel.*",
                "panel.fi.*",
                "panel.se.*",
                "panel.dk.*",
                "panel.de.streaming-objects",
                "panel.dk.streaming-objects",
                "panel.fi.streaming-objects",
                "panel.se.streaming-objects",
            ],
            navigation: true,
            domicile: true,
        },
        component: () => import("./pages/panel/PanelStreamingObjectsPage.vue"),
    },
    // Market data
    {
        path: "/dashboard/market-data-status/:domicile?",
        name: "market-data-status",
        meta: {
            parent: "Market data",
            title: "Status",
            icon: "chart-line",
            target: [
                "market-data.*",
                "market-data.de.*",
                "market-data.dk.*",
                "market-data.fi.*",
                "market-data.se.*",
                "market-data.de.status",
                "market-data.dk.status",
                "market-data.fi.status",
                "market-data.se.status",
            ],
            navigation: true,
            domicile: true,
        },
        component: () => import("./pages/market-data/MarketDataStatusPage.vue"),
    },
    {
        path: "/dashboard/market-data-domain-report/:id?",
        name: "market-data-domain-report",
        meta: {
            parent: "Market data",
            title: "Domain report",
            icon: "percentage",
            target: [
                "market-data.*",
                "market-data.de.*",
                "market-data.dk.*",
                "market-data.fi.*",
                "market-data.se.*",
                "market-data.de.status",
                "market-data.dk.status",
                "market-data.fi.status",
                "market-data.se.status",
            ],
            navigation: true,
        },
        component: () =>
            import("./pages/market-data/MarketDataDomainReportPage.vue"),
    },
    // AdTention
    {
        path: "/dashboard/adtention-measurements/:domicile?",
        name: "adtention-measurements",
        meta: {
            parent: "AdTention",
            title: "Measurements",
            icon: "chart-bar",
            target: [
                "adtention.*",
                "adtention.de.measurements",
                "adtention.dk.measurements",
                "adtention.fi.measurements",
                "adtention.se.measurements",
            ],
            navigation: true,
            domicile: true,
        },
        component: () =>
            import("./pages/adtention/AdTentionMeasurementsPage.vue"),
    },
    // AI
    {
        path: "/dashboard/ai-status",
        name: "ai-status",
        meta: {
            parent: "AI",
            title: "Status",
            icon: "sitemap",
            target: ["system.*"],
            navigation: true,
        },
        component: () => import("./pages/ai/AiStatus.vue"),
    },
    {
        path: "/dashboard/ai-version",
        name: "ai-version",
        meta: {
            parent: "AI",
            title: "Version",
            icon: "sliders-v",
            target: ["system.*"],
            navigation: true,
        },
        component: () => import("./pages/ai/AiVersion.vue"),
    },
    {
        path: "/dashboard/ai-analytics",
        name: "ai-analytics",
        meta: {
            parent: "AI",
            title: "Analytics",
            icon: "chart-bar",
            target: ["system.*"],
            navigation: true,
        },
        component: () => import("./pages/ai/AIAnalytics.vue"),
    },
    // Dekaani
    {
        path: "/dashboard/dekaani-queue",
        name: "dekaani-queue",
        meta: {
            parent: "Dekaani",
            title: "Queue",
            icon: "directions",
            target: ["system.*"],
            navigation: true,
        },
        component: () => import("./pages/dekaani/DekaaniStatus.vue"),
    },
    {
        path: "/dashboard/dekaani-input",
        name: "dekaani-input",
        meta: {
            parent: "Dekaani",
            title: "Input",
            icon: "arrow-circle-up",
            target: ["system.*"],
            navigation: true,
        },
        component: () => import("./pages/dekaani/DekaaniStatus.vue"),
    },
    {
        path: "/dashboard/dekaani-output",
        name: "dekaani-output",
        meta: {
            parent: "Dekaani",
            title: "Output",
            icon: "arrow-circle-down",
            target: ["system.*"],
            navigation: true,
        },
        component: () => import("./pages/dekaani/DekaaniStatus.vue"),
    },
    {
        path: "/dashboard/dekaani-list/:id?",
        name: "dekaani-list",
        meta: {
            parent: "Dekaani",
            title: "Task list",
            icon: "list",
            target: ["system.*"],
            navigation: true,
        },
        component: () => import("./pages/dekaani/DekaaniStatus.vue"),
    },
    // 404
    {
        path: "/:pathMatch(.*)*",
        name: "404",
        meta: {
            title: "404 Not Found",
        },
        component: () => import("./pages/Error404Page.vue"),
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
    document.title = `${to.meta.parent ? to.meta.parent + " - " : ""}${
        to.meta.title
    } - Viomba Dashboard`;

    try {
        const visible = await checkActiveNavItemVisible(to);

        if (!visible) {
            const navigation = document.querySelector(".navigation");
            const activePage = navigation!.querySelector(
                "[data-route-name='" + to.name?.toString() + "']"
            );
            navigation!.scrollTop =
                activePage!.getBoundingClientRect().top -
                navigation!.getBoundingClientRect().top -
                14;
        }
        // eslint-disable-next-line no-empty
    } catch {}

    if (from.name === to.name) {
        next();
    } else {
        await store.dispatch("auth/updateUserDetails").then(() => {
            if (!store.state.auth.loggedIn) {
                if (to.name !== "login") {
                    next("/");
                } else {
                    next();
                }

                // Save landing page in case a redirect is needed after logging in
                if (to.redirectedFrom)
                    document.cookie = "redirect=" + to.redirectedFrom.fullPath;
            } else {
                if (to.name == "login" || !checkAccess(to.matched[0])) {
                    next("/dashboard/home");
                } else {
                    next();
                }
            }
        });
    }
});

export const getNavigationItems = () => {
    const navigationItems: any = [];

    routes.reduce((routes: any, route: any) => {
        if (!route.meta || !route.meta.navigation || route.meta.hideInNavigation || !checkAccess(route))
            return routes;
        navigationItems.find((i: any) => i.title == route.meta.parent) ||
            navigationItems.push({ title: route.meta.parent, routes: [] });
        route.path = route.path.split("/").slice(0, 3).join("/");
        navigationItems[navigationItems.length - 1].routes.push(route);
        return routes;
    }, {});

    return navigationItems;
};

export default router;
