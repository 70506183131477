/**
 * API calls regarding notifications. User is required to be logged in.
 */


import axios from './index';



export default class NotificationService {

	static async getNotifications(page: number = 1): Promise<any> {
		const response = await axios
			.get(
				'get-notifications?page=' + page
			);

		if (response.data.status == 'success') {
			return response.data;
		} else {
			throw new Error()
		}
	}

	static async getNotificationSettings(): Promise<any> {
		const response = await axios
			.get(
				'get-notification-settings'
			);

		if (response.data.status == 'success') {
			return response.data;
		} else {
			throw new Error()
		}
	}

	static async setNotificationSettings(settings: any): Promise<any> {
		const response = await axios
			.post(
				'set-notification-settings',
				{ settings }
			);

		if (response.data.status == 'success') {
			return response.data;
		} else {
			throw new Error()
		}
	}

	static async getNotification(id: string|null): Promise<any> {
		const response = await axios
			.get(
				'get-notification' + '/' + id
			);

		return response.data;
	}

	static async markAsRead(id: string|null): Promise<any> {
		const response = await axios
			.post(
				'notification-read',
				id ? { id } : ''
			);

		if (response.data.status == 'success') {
			return response.data;
		} else {
			throw new Error()
		}
	}

}