import { createStore } from "vuex";
import { auth, AuthState } from "./AuthModule"
import { ui, UiState } from "./UiModule";

export interface State {
    auth: AuthState
    ui: UiState
}

const store = createStore({
    modules: {
        auth,
        ui
    }
});

export default store;