/**
 * API calls regarding user info/settings. User is required to be logged in to change settings.
 */


import axios from './index';
import domiciles from '../domiciles.json';


export interface NewCredentials {
	email?: string,
	password?: string
}

export default class UserService {


	static async getMe(): Promise<any> {
		const response = await axios.get('me');

		if (response.data.status == 'success') {
			response.data.data['panel_domiciles'] = Array.from([...new Set((response.data.data.role.permissions.flatMap((p: string) => p.includes('panel.*') ? domiciles : p.includes('panel.') ? domiciles.find(d => d.code == p.split('.')[1]) : []).sort((a: any, b: any) => a.name > b.name && 1 || -1)))]);
			response.data.data['market_domiciles'] = Array.from([...new Set((response.data.data.role.permissions.flatMap((p: string) => p.includes('market-data.*') ? domiciles : p.includes('market-data.') ? domiciles.find(d => d.code == p.split('.')[1]) : []).sort((a: any, b: any) => a.name > b.name && 1 || -1)))]);
			response.data.data['adtention_domiciles'] = Array.from([...new Set((response.data.data.role.permissions.flatMap((p: string) => p.includes('adtention.*') ? domiciles : p.includes('adtention.') ? domiciles.find(d => d.code == p.split('.')[1]) : []).sort((a: any, b: any) => a.name > b.name && 1 || -1)))]);
			return response.data;
		} else {
			throw new Error()
		}
	}

	static async setCretentials(credentials: NewCredentials): Promise<any> {
		const response = await axios
			.post(
				'set-credentials',
				credentials,
			);

		if (response.data.status == 'success') {
			return response.data;
		} else {
			throw new Error()
		}
	}

}