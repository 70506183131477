/**
 * API calls regarding authentication.
 */


import axios from './index';

export interface LoginCredentials {
	email: string,
	password: string
}

export default class AuthService {

	static async logIn(credentials: LoginCredentials): Promise<any> {
		const response = await axios
			.post(
				'auth/login',
				credentials
			);
		
		if (response.data.status == 'success') {
			return response.data;
		} else {
			throw new Error(response.data)
		}
	}

	static async setPasswordAndLogin(credentials: LoginCredentials, token: string): Promise<any> {
		const response = await axios
			.post(
				'set-password',
				{ ...credentials, token }
			);

		if (response.data.status == 'success') {
			await this.logIn(credentials);
		} else {
			throw new Error(response.data.error)
		}
	}

	static async logOut(): Promise<any> {
		const response = await axios.post('auth/logout');
		document.cookie = "redirect=";

		if (response.data.status == 'success') {
			return response.data;
		} else {
			throw new Error(response.data.error)
		}
	}

	static async resetPassword(email: string): Promise<any> {
		const response = await axios
			.post(
				'reset-password', 
				{ email },
			);

		if (response.data.status == 'success') {
			return response.data;
		} else {
			throw new Error()
		}
	}

}
