
	import { defineComponent, onMounted, ref } from 'vue';
	import { useStore } from 'vuex';
	import { useRoute, useRouter } from 'vue-router';
    import UserService, { NewCredentials } from '@/services/UserService';
    import { useToast } from 'primevue/usetoast';
    import roles from '@/user_roles.json'
    import { getNavigationItems } from '@/router';

	export default defineComponent({
        emits: ["openNotificationSettings"],
		setup(props, { emit }) {
            const route = useRoute();
			const router = useRouter();
			const store = useStore();
            const toast = useToast();

            const navigationItems = getNavigationItems();

            const modal = ref({
                settings: false
            })

            const loading = ref({
                setCredentials: false
            })

            const credentials = ref({
                email: store.state.auth.user.email,
                password: ''
            } as NewCredentials)


            onMounted(() => {
                try {
                    const navigation = document.querySelector('.navigation');
                    const activePage = navigation!.querySelector("[data-route-name='" + route.name?.toString() + "']");
                    navigation!.scrollTop = activePage!.getBoundingClientRect().top - navigation!.getBoundingClientRect().top - 14;
                // eslint-disable-next-line no-empty
                } catch {
                }
            });

			const logOut = () => {
				store.dispatch("auth/logOut").then(() => {
						router.push("/");
					}
				);
			}

            const setCredentials = () => {
                loading.value.setCredentials = true;
                const newCredentials = credentials.value;
                if (newCredentials.password == '') delete newCredentials.password;

                UserService.setCretentials(newCredentials)
                .then(() => {
                    store.dispatch("auth/updateUserDetails")
                    .then(() => {
                        toast.add({severity: 'success', summary: 'Settings saved successfully', life: 5000});
                        loading.value.setCredentials = false;
                    });
                })
                .catch(() => {
                    toast.add({severity: 'error', summary: 'Saving settings failed', life: 5000});
                });
            }

			return {
                credentials,
                loading,
                modal,
                navigationItems,
				store,
                roles,

                emit,
				logOut,
                setCredentials
			}
		}
	})
