
    import AppSideMenu from '@/components/AppSideMenu.vue';
    import AppTopBar from '@/components/AppTopBar.vue';
    import AppFooter from '@/components/AppFooter.vue';
    import { ref } from 'vue';

    export default {
        components: {
            AppSideMenu,
            AppTopBar,
            AppFooter,
        },
        setup() {
            const showNotificationSettings = ref(0);

            const openNotificationSettings = () => {
                showNotificationSettings.value++;
            }

            return {
                showNotificationSettings,

                openNotificationSettings
            }
        }
    }
