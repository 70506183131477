/**
 * Module for generic UI states.
 */

export interface UiState {
    loading: boolean,
    default_domicile?: string|null,
    devmode?: boolean
}

export const ui = {
    namespaced: true,
    state: {
        loading: false,
        default_domicile: localStorage.getItem("default_domicile") || undefined,
        devmode: localStorage.getItem("devmode") == "1"
    },
    mutations: {
        showLoading(state: UiState) {
            state.loading = true;
        },
        hideLoading(state: UiState) {
            state.loading = false;
        },
        setDefaultDomicile(state: UiState, domicile: string) {
            state.default_domicile = domicile;
            localStorage.setItem("default_domicile", domicile);
        },
        resetDefaultDomicile(state: UiState) {
            state.default_domicile = undefined;
            localStorage.removeItem("default_domicile");
        },
        toggleDevMode(state: UiState) {
            state.devmode = !state.devmode;
            localStorage.setItem("devmode", state.devmode ? "1" : "0");
            location.reload();
        }
    }
};
